<template>
  <div class="basko-box-timeslot-container">
    <TimeslotSelector
      :addressId="addressId"
      :isNotPopup="true"
      @timeslotSelected="timeslotSelected"
    />
  </div>
</template>
<style lang="scss">
.basko-box-timeslot-container {
  .timeslot-selector {
    padding: 0px;
  }
}
</style>
<script>
import TimeslotSelector from "@/components/delivery/TimeslotSelector.vue";

export default {
  name: "BaskoBoxTimeslot",
  components: {
    TimeslotSelector
  },
  props: {
    addressId: { type: String, required: true }
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {},
  methods: {
    async timeslotSelected(params) {
      this.$router.push({
        name: "BaskoBoxConfirmConsent",
        query: {
          timeslotId: params.timeslotId,
          weekDay: params.weekDay,
          addressId: this.addressId
        }
      });
    }
  },
  async mounted() {}
};
</script>
